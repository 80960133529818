import { Icon } from "../Icon";
import { Text } from "../Text";
import type { Breadcrumb } from "./Breadcrumbs";
import { LinkMobile, List, ListItem } from "./Breadcrumbs.styles";
import { Separator } from "./Separator";

type Props = {
  firstBreadcrumb: Breadcrumb;
  homeUrl: string;
  /**Whether to use h1 html element for the last breadcrumb or not */
  includeH1?: boolean;
  lastBreadcrumb: Breadcrumb;
};
export const BreadcrumbsMobile = ({
  firstBreadcrumb,
  homeUrl,
  includeH1 = false,
  lastBreadcrumb,
}: Props) => {
  return (
    <List
      data-testid="breadcrumbs"
      itemScope={true}
      itemType="https://schema.org/BreadcrumbList"
    >
      <ListItem
        itemProp="itemListElement"
        itemScope={true}
        itemType="https://schema.org/ListItem"
      >
        <LinkMobile
          href={`${homeUrl}${firstBreadcrumb.url}`}
          itemProp="item"
          scroll={false}
        >
          <Icon name="CaretLeft" color="webBlack" />
          <Text color="webBlack" itemProp="name">
            {firstBreadcrumb.name}
          </Text>
        </LinkMobile>
        <meta itemProp="position" content="1" />
        <Separator />
      </ListItem>
      <ListItem>
        <LinkMobile
          href={`${homeUrl}${lastBreadcrumb.url}`}
          itemProp="item"
          scroll={false}
        >
          <Text
            as={includeH1 ? "h1" : undefined}
            margin={includeH1 ? "0" : undefined}
            fontFamily="medium"
            color="webBlack"
            itemProp="name"
          >
            {lastBreadcrumb.name}
          </Text>
        </LinkMobile>
        <meta itemProp="position" content="2" />
      </ListItem>
    </List>
  );
};
