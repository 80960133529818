import type { ReactNode } from "react";
import React from "react";
import { styled } from "@mui/material";
import { colorsAsCssVariable as colors } from "../../../styles/theme/colors";
import { MQ, interactionFeedbackForStyledMui } from "../../../styles/helpers";
import { NextLink } from "@/next-js-app/components/common/NextLink/NextLink";

export const HighlightedCategoryStyled = styled(NextLink)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "flex-start",
  flex: 1,
  height: "68px",

  [`${MQ("smallTablet")}`]: {
    height: "130px",
  },

  "& img": {
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    position: "absolute",
    objectFit: "cover",
    zIndex: 1,
    filter: "brightness(80%)",
  },
  ...interactionFeedbackForStyledMui({
    backgroundColor: colors.xxlGreen,
    color: colors.xxlBlack,

    "& img": {
      display: "none",
    },
  }),
});

export const ImageWrapper = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
  ...interactionFeedbackForStyledMui({
    backgroundColor: colors.xxlGreen,
  }),
});

const HighlightedCategoryTitleStyled = styled("p")({
  margin: 0,
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  fontSize: 15,
  lineHeight: 1.2,
  fontFamily: "var(--font-family-bold)",
  textAlign: "center",
  padding: "0 10px",
  boxSizing: "border-box",
  ...interactionFeedbackForStyledMui({
    color: colors.xxlBlack,
  }),

  [`${MQ("smallTablet")}`]: {
    fontSize: 24,
    padding: "37px 24px",
  },
});

export const HighlightedCategoryTitle: React.FunctionComponent<{
  children?: ReactNode;
}> = (props) => (
  <HighlightedCategoryTitleStyled>
    {props.children}
  </HighlightedCategoryTitleStyled>
);
