import { Text } from "../Text";
import { List, ListItem, Link } from "./Breadcrumbs.styles";
import { Separator } from "./Separator";
import type { BreadcrumbsProps } from "./Breadcrumbs";

export const BreadcrumbsDesktop = ({
  breadcrumbs,
  homeUrl,
}: BreadcrumbsProps) => {
  if (breadcrumbs.length === 0) return null;
  return (
    <List
      data-testid="breadcrumbs"
      itemScope={true}
      itemType="https://schema.org/BreadcrumbList"
    >
      {breadcrumbs.map(({ name, url }, index) => {
        const isLast = index === breadcrumbs.length - 1;
        return (
          <ListItem
            key={index}
            itemProp="itemListElement"
            itemScope={true}
            itemType="https://schema.org/ListItem"
          >
            <Link href={`${homeUrl}${url}`} itemProp="item" scroll={false}>
              <Text
                fontFamily={isLast ? "bold" : "regular"}
                color={isLast ? "webBlack" : "webGray"}
                itemProp="name"
              >
                {name}
              </Text>
            </Link>
            <meta itemProp="position" content={String(index + 1)} />
            {isLast ? null : <Separator />}
          </ListItem>
        );
      })}
    </List>
  );
};
