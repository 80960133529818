import { hasValue } from "@xxl/common-utils";
import type { BuyingGuide } from "@xxl/content-api";
import { ArrowRight } from "@xxl/icons";
import ReactHtmlParser from "html-react-parser";
import React from "react";
import Breakpoints from "react-app/src/styles/breakpoints.config";
import { getHotspot } from "react-app/src/utils/xxl-image";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { withErrorBoundary } from "../../../utils/WithErrorBoundary/with-error-boundary";
import {
  ButtonContent,
  GuideGridContainer,
  GuideHeroButton,
  GuideHeroHeader,
  GuideHeroParagraph,
  NextImageGridContainer,
  NextImageStyled,
} from "./BuyingGuideHeaderCategoryHero.styled";

type BuyingGuideType = BuyingGuide & {
  buttons?: ({ displayName?: string } | undefined)[];
};

type BuyingGuideHeaderCategoryHeroProps = {
  categoryName: string | undefined;
  buyingGuide: BuyingGuideType;
};

const _BuyingGuideHeaderCategoryHero = ({
  categoryName,
  buyingGuide,
}: BuyingGuideHeaderCategoryHeroProps) => {
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const { image, buttons, metaDescription } = buyingGuide;
  const [button] = buttons ?? [];
  const showHeader = isLaptop && hasValue(categoryName);
  const hasImage = hasValue(image) && hasValue(image.url);
  const showImage = isLaptop && hasImage;
  const showDescription = hasValue(metaDescription);
  const showButton = hasValue(button?.displayName);
  const sizes = `
    (max-width: ${Breakpoints.laptop}px) 640px,
    750px
  `;

  const scrollToGuideSection = () => {
    const position = document.getElementById("buying-guide-content")?.offsetTop;
    window.scrollTo({
      top: position,
      behavior: "smooth",
    });
  };

  return (
    <GuideGridContainer hasImage={hasImage}>
      {showHeader && <GuideHeroHeader>{categoryName}</GuideHeroHeader>}
      {showDescription && (
        <GuideHeroParagraph>
          {ReactHtmlParser(metaDescription)}
        </GuideHeroParagraph>
      )}
      {showButton && (
        <GuideHeroButton
          variant="secondary"
          width={isLaptop ? "auto" : "full"}
          size="small"
          onClick={scrollToGuideSection}
        >
          <ButtonContent>
            {button.displayName}
            <ArrowRight />
          </ButtonContent>
        </GuideHeroButton>
      )}
      {showImage && (
        <NextImageGridContainer>
          <NextImageStyled
            alt={image.alt ?? ""}
            hotspot={getHotspot(image)}
            sizes={sizes}
            src={image.url ?? ""}
          />
        </NextImageGridContainer>
      )}
    </GuideGridContainer>
  );
};

_BuyingGuideHeaderCategoryHero.displayName = "BuyingGuideHeaderCategoryHero";
export const BuyingGuideHeaderCategoryHero = withErrorBoundary(
  _BuyingGuideHeaderCategoryHero
);
