import styled from "@emotion/styled/macro";
import type { Breakpoint } from "../../../styles/breakpoints.config";
import { MQ } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
import { BANNER_HEIGHTS } from "../../Banners/Banner.styled";
import { XxlButton } from "../../Common/XxlButton";
import { NextImage } from "../../NextComponentsStubs/NextImage";
import { PageHeader } from "./Header.styled";

const breakpoint: Breakpoint = "laptop";

const { spaces } = xxlTheme;

export const GuideGridContainer = styled.div<{ hasImage: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "description"
    "button";

  gap: ${spaces.large};
  ${MQ(breakpoint)} {
    gap: ${spaces.big};
  }

  ${({ hasImage }) =>
    hasImage
      ? `${MQ(breakpoint)} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "header image"
    "description image"
    "button image";
    }`
      : `${MQ(breakpoint)} {
    grid-template-areas:
    "header"
    "description"
    "button";
  }`}
`;

export const GuideHeroHeader = styled(PageHeader)`
  grid-area: header;
  margin: 0;
`;

export const GuideHeroParagraph = styled.p`
  grid-area: description;
  margin: 0;
`;

export const GuideHeroButton = styled(XxlButton)`
  grid-area: button;
  justify-self: self-start;
`;

export const ButtonContent = styled.span`
  display: flex;
  gap: ${spaces.miniMicro};
  align-items: center;
  width: 100%;
  &&& {
    justify-content: space-between;
    ${MQ(breakpoint)} {
      justify-content: center;
    }
  }
`;

export const NextImageGridContainer = styled.div`
  grid-area: image;
  overflow: hidden;
  position: relative;
`;

export const NextImageStyled = styled(NextImage)`
  position: absolute;
  object-fit: cover;
  min-height: ${BANNER_HEIGHTS.small.mobile};
  max-width: 100%;
`;
