import { styled } from "@mui/material/styles";
import { MQ, xxlScrollbars } from "../../../styles/helpers";
import spaces from "../../../styles/theme/spaces";
import { FadingLoader } from "../SearchContent.styled";
import isPropValid from "@emotion/is-prop-valid";

export const SubCategoriesListStyled = styled(FadingLoader, {
  shouldForwardProp: isPropValid,
})`
  overflow-x: auto;
  white-space: nowrap;
  ${xxlScrollbars(spaces.micro)};
  width: 100%;
  display: flex;
  padding-bottom: ${spaces.smallRegular};
  margin-bottom: ${spaces.large};
  gap: ${spaces.mini};

  ${MQ("tablet")} {
    overflow: visible;
    flex-wrap: wrap;
    margin-bottom: calc(${spaces.big} - ${spaces.mini});
  },
`;
