import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../styles/xxl-theme";
import { color } from "@xxl/theme";
// eslint-disable-next-line import/no-extraneous-dependencies
import { NextLink } from "@/next-js-app/components/common/NextLink/NextLink";

export const Link = styled(NextLink)`
  text-decoration: none;
`;

export const LinkMobile = styled(Link)`
  color: ${color.webBlack.cssVariable};
  display: flex;
`;

export const List = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  gap: ${xxlTheme.spaces.micro};
  flex-wrap: wrap;
`;

export const ListItem = styled.li`
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  gap: ${xxlTheme.spaces.micro};
`;
