import type { NextImageProps } from "@/next-js-app/components/common/NextImage/NextImage.types";
import { ImageWithFetchPrio } from "react-app/src/components/Common/Image/ImageWithFetchPrio";

export const NextImage = ({ alt, priority, src, ...props }: NextImageProps) => {
  const fetchPriority =
    priority === undefined ? "auto" : priority ? "high" : "low";
  return (
    <ImageWithFetchPrio
      alt={alt}
      fetchPriority={fetchPriority}
      src={src.toString()}
      {...props}
    />
  );
};
