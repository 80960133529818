import type { CategoryData } from "@xxl/frontend-api";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "../../hooks/useXxlMediaQuery";
import { withErrorBoundary } from "../../utils/WithErrorBoundary/with-error-boundary";
import { BreadcrumbsDesktop } from "./Breadcrumbs.desktop";
import { BreadcrumbsMobile } from "./Breadcrumbs.mobile";

export type Breadcrumb = CategoryData & {
  name: string;
  url: string;
};

export type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
  homeUrl: string;
  /** Whether to include the last breadcrumb (category name) as h1 on mobile or not */
  includeH1?: boolean;
};

const _Breadcrumbs = ({
  breadcrumbs,
  homeUrl,
  includeH1 = false,
}: BreadcrumbsProps) => {
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const { t } = useTranslations();

  if (breadcrumbs.length === 0) {
    return null;
  }

  if (isLaptopSize) {
    breadcrumbs[0].name = t("breadcrumbs.home");
    breadcrumbs[0].url = "/";
    const desktopBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1);

    return (
      <BreadcrumbsDesktop breadcrumbs={desktopBreadcrumbs} homeUrl={homeUrl} />
    );
  }

  const firstBreadcrumb = includeH1 ? breadcrumbs.at(-2) : breadcrumbs.at(-3);
  const lastBreadcrumb = includeH1 ? breadcrumbs.at(-1) : breadcrumbs.at(-2);

  if (firstBreadcrumb === undefined || lastBreadcrumb === undefined) {
    return null;
  }

  return (
    <BreadcrumbsMobile
      includeH1={includeH1}
      firstBreadcrumb={firstBreadcrumb}
      lastBreadcrumb={lastBreadcrumb}
      homeUrl={homeUrl}
    />
  );
};

export const Breadcrumbs = withErrorBoundary(_Breadcrumbs);
