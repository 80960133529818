import type { CSSProperties, MouseEvent } from "react";
import React from "react";
import type { HighlightedCategoryData } from "../SearchState";
import { useSearchContext } from "../SearchState";
import { HighlightedCategory } from "./HighlightedCategory";
import { FadingLoader } from "../SearchContent.styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import { laptopMediaQuery } from "../../../utils/xxl-screen";
import { containerMaxWidth } from "../../../styles/xxl-theme";
import { Slider } from "../../Common/Slider";

type SubCategoriesListProps = {
  highlightedCategories: HighlightedCategoryData[];
  isLoading?: boolean;
  onClick:
    | ((code: string) => (event: MouseEvent<HTMLAnchorElement>) => void)
    | null;
  scrollable?: boolean;
  style?: CSSProperties;
};

const HighlightedCategoriesList = ({
  highlightedCategories,
  onClick,
  style,
}: SubCategoriesListProps) => {
  const { state } = useSearchContext();
  const isLaptop = useMediaQuery(laptopMediaQuery);
  const { isFetchingCategoryData } = state;
  const totalSlidesCount = highlightedCategories.length;
  const maxDesktopVisibleSlides = 6;
  const desktopCount = Math.min(totalSlidesCount, maxDesktopVisibleSlides);
  const dekstopItemWidth = Math.round(containerMaxWidth / desktopCount);

  return (
    <FadingLoader isLoading={isFetchingCategoryData}>
      <div style={style}>
        <Slider
          items={highlightedCategories.map((item) => {
            return (
              <HighlightedCategory
                deksktopItemWidth={dekstopItemWidth}
                category={item}
                key={item.name}
                onClick={onClick}
              />
            );
          })}
          slidesConfig={{
            perView: isLaptop ? desktopCount : 2.1,
            spacing: isLaptop ? 12 : 8,
          }}
          mode="free-snap"
        />
      </div>
    </FadingLoader>
  );
};

export { HighlightedCategoriesList };
