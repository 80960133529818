import type { MouseEvent } from "react";
import React from "react";
import { getImageUrl } from "../../../utils/xxl-image";
import type { ImageData } from "@xxl/frontend-api";
import type { HighlightedCategoryData } from "../SearchState";
import {
  HighlightedCategoryStyled,
  HighlightedCategoryTitle,
  ImageWrapper,
} from "./HighlightedCategory.styled";
import { xxlTheme } from "../../../styles/xxl-theme";
import Breakpoints from "../../../styles/breakpoints.config";
import { MISSING_PRODUCT_IMAGE_URL } from "../../../constants";
import { hasNoValue } from "@xxl/common-utils";

const getFullUrl = (
  image: ImageData | undefined,
  width: number,
  height: number
) => {
  return image?.baseUrl !== undefined ? getImageUrl(image, width, height) : "";
};

type SubCategoriesListProps = {
  category: HighlightedCategoryData;
  deksktopItemWidth: number;
  onClick:
    | ((categoryCode: string) => (event: MouseEvent<HTMLAnchorElement>) => void)
    | null;
};

export const HighlightedCategory = ({
  category,
  deksktopItemWidth,
  onClick,
}: SubCategoriesListProps) => {
  const { xxlGrey } = xxlTheme.colors;

  const srcSet = `
  ${getFullUrl(category.image, 220, 68)} 220w,
  ${getFullUrl(category.image, 470, 130)} 470w,
  ${getFullUrl(category.image, deksktopItemWidth, 130)} 522w,
  `;
  const sizes = `
  (max-width: 499px) 220px, 
  (max-width: ${Breakpoints.tabletHorizontal}px) 470px, 
  522w,
  `;
  const altText = category.image?.alt;
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (hasNoValue(onClick)) {
      return;
    }
    onClick(category.categoryCode)(event);
  };

  return (
    <HighlightedCategoryStyled href={category.url} onClick={handleClick}>
      {category.image?.baseUrl !== undefined ? (
        <ImageWrapper>
          <img
            srcSet={srcSet}
            sizes={sizes}
            src={`${getFullUrl(category.image, 660, 130)}`}
            alt={altText}
            onError={(event: React.InvalidEvent<HTMLImageElement>) => {
              event.target.src = `${window.location.origin}${MISSING_PRODUCT_IMAGE_URL}`;
              event.target.srcset = `${window.location.origin}${MISSING_PRODUCT_IMAGE_URL}`;
            }}
          />
          <HighlightedCategoryTitle>{category.name} </HighlightedCategoryTitle>
        </ImageWrapper>
      ) : (
        <ImageWrapper
          sx={{
            backgroundColor:
              category.backgroundColor !== undefined
                ? category.backgroundColor
                : xxlGrey,
          }}
        >
          <HighlightedCategoryTitle>{category.name}</HighlightedCategoryTitle>
        </ImageWrapper>
      )}
    </HighlightedCategoryStyled>
  );
};
