import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material";
import { interactionFeedbackForStyledMui } from "../../../styles/helpers";
import { colorsAsCssVariable as colors } from "../../../styles/theme/colors";
import spaces from "../../../styles/theme/spaces";
// eslint-disable-next-line import/no-extraneous-dependencies
import { NextLink } from "@/next-js-app/components/common/NextLink/NextLink";

const subCategoryLinkStyle = {
  backgroundColor: colors.xxlLightGrey,
  padding: `${spaces.smallRegular} ${spaces.regular}`,
  fontSize: 15,
  cursor: "pointer",
  display: "flex",
  "& .MuiSvgIcon-root": {
    marginRight: 5,
  },
  ...interactionFeedbackForStyledMui({
    backgroundColor: colors.xxlGreen,
  }),
};

export const SubCategoryLinkStyled = styled("a")(subCategoryLinkStyle);
export const SubCategoryNextLinkStyled = styled(NextLink)(subCategoryLinkStyle);

export const BackArrowStyled = styled(ArrowBackIcon)({
  fontSize: 17,
});
